import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SPEAKER_ENTITY_LIST_FRAGMENT = `
  fragment speakerEntityListFragment on Speaker {
    id
    uid
    __typename
    schemaCode
    firstName
    lastName
    name
    prefix
    suffix
    jobTitle
    employerName
    photoFileResource {
      ...fileResourceBaseFragment
    }
    _displaySequence(sessionUid: "%sessionId%")
    _isBookmarked(myUid: "%authUser%")
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
